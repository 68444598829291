import { child, get, ref, getDatabase } from 'firebase/database';
import appUtils from '../components/utils/appUtils.js';
import { defineStore } from 'pinia';
import { getAuth } from 'firebase/auth';
import config from '../store/global';
import { initializeApp } from 'firebase/app';

//HOW TO USE:
//import { useInvestStore } from '../../stores/InvestStore';
//const investStore = useInvestStore();

export const useInvestStore = defineStore('InvestStore', {
  state: () => {
    return {
      trades: [],
      tradesWithGuid: [],
      dividendsWithGuid: [],
    };
  },
  getters: {
    getTradeFields() {
      return [
        'symbol',
        'description',
        'currency',
        'buySell',
        'tradeDate',
        'tradePrice',
        'tradeMoney',
        'quantity',
        'currency',
        'dateFormated',
        'fifoPnlRealized',
        'ibCommission',
        'fxRateToBase',
      ];
    },

    getDividendFields() {
      // return [
      //   'currency',
      //   'symbol',
      //   'description',
      //   'isin',
      //   'issuerCountryCode',
      //   'payDate',
      //   'payDateFormated',
      //   'tax',
      //   'fee',
      //   'grossRate',
      //   'grossAmount',
      //   'netAmount',
      // ];
      return [
        'symbol',
        'currency',
        'date',
        'description',
        'dividend',
        'imported',
      ];
    },
  },

  actions: {
    setSorting(payload) {
      this.tradesWithGuid = appUtils.setSortingUtil(
        payload,
        this.tradesWithGuid
      );
    },

    setSortingTrade(payload) {
      this.tradesWithGuid = appUtils.setSortingUtil(
        payload,
        this.tradesWithGuid
      );
    },

    setSortingDividend(payload) {
      this.dividendsWithGuid = appUtils.setSortingUtil(
        payload,
        this.dividendsWithGuid
      );
    },

    getUniqueSymbols() {
      const uniqueArray = [
        ...new Map(
          this.tradesWithGuid.map((item) => [item.symbol, item])
        ).values(),
      ];
      return uniqueArray;
    },

    loadInvestFirebase(payload) {
      let promise = new Promise((resolve, reject) => {
        try {
          console.log('loadInvestFirebase');

          let db;
          if (payload) {
            db = payload.db;
          } else {
            const firebaseConfig = config.global().firebaseConfig;
            const app = initializeApp(firebaseConfig);
            db = getDatabase(app);
            db = {
              db,
            };
          }

          let userUid = getAuth().currentUser.uid;

          get(child(ref(db), `invest_trades/${userUid}`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                this.symbols = snapshot.val();

                let props = this.getTradeFields;
                let tmpArray = appUtils.getArrayWithGuid(snapshot.val(), props);

                this.tradesWithGuid = tmpArray;
                console.log(
                  '=========== InvestStore => tradesWithGuid ==========='
                );
                console.log(tmpArray);
                resolve();
              } else {
                resolve();
                this.trades = [];
                this.tradesWithGuid = [];
                // console.log('No data available.');
              }
            })
            .catch((error) => {
              reject(error.message);
              throw error;
              // console.error(error);
            });
        } catch (error) {
          reject(error.message);
          throw error;
        }
      });
      return promise;
    },

    loadDividendsFirebase(payload, parYear) {
      let promise = new Promise((resolve, reject) => {
        try {
          console.log('loadDividendsFirebase');

          let db;
          if (payload) {
            db = payload.db;
          } else {
            const firebaseConfig = config.global().firebaseConfig;
            const app = initializeApp(firebaseConfig);
            db = getDatabase(app);
          }

          // const db = payload.db;
          let userUid = getAuth().currentUser.uid;

          get(child(ref(db), `invest_dividends/${userUid}/${parYear}`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                let props = this.getDividendFields;
                let tmpArray = appUtils.getArrayWithGuid(snapshot.val(), props);

                this.dividendsWithGuid = tmpArray; //snapshot.val();
                console.log(
                  '=========== InvestStore => dividendsWithGuid ==========='
                );
                //console.log(tmpArray);
                resolve();
              } else {
                resolve();
                this.dividendsWithGuid = [];
                // console.log('No data available.');
              }
            })
            .catch((error) => {
              reject(error.message);
              throw error;
              // console.error(error);
            });
        } catch (error) {
          reject(error.message);
          throw error;
        }
      });
      return promise;
    },
  },
});
